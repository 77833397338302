import React from 'react';
import ed from './ed_sheeran_face-small.png';
import './App.css';
import Countdown from './Countdown';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={ed} className="App-logo" alt="logo" />
      <Countdown />
      </header>
    </div>
  );
}

export default App;
