import React from "react";

type Props = {
  label: string;
  value: number;
};

export default function CountdownElement({ label, value }: Props) {
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: 15 }}>
      <h1>{value}</h1>
      <h2>{label}</h2>
    </div>
  );
}
