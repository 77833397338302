import React, { useEffect, useState } from "react";
import CountdownElement from "./CountdownElement";

type Props = {};

interface TimeLeftI {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const targetDate: Date = new Date(2024, 7, 24);

const calculateTimeLeft = (): TimeLeftI => {
  let difference = +targetDate - +new Date();

  let timeLeft: TimeLeftI | any = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export default function Countdown({}: Props) {
  const [timeLeft, setTimeLeft] = useState<TimeLeftI>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  console.log(timeLeft);

  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          {Object.entries(timeLeft).map((el) => (
            <CountdownElement label={el[0]} value={el[1]} />
          ))}
        </div>
        <h4 style={{ marginBottom: 0 }}>Target date</h4>
        <p>
          {targetDate.toLocaleDateString("en-US", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
      </div>
    </div>
  );
}
